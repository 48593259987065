import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import licenseController from '@/licenseController'
import authenticationController from '@/authenticationController'
const navigation = import('@/navigationController')

Vue.use(VueRouter)

const handleAccessFromToken = async function (to, from, next) {
  const r = await (await navigation).default.navigateTo(to.params.id, to.meta.documentType)
  if (r?.response?.status === 404) {
    next('not-found')
  }
  next(false)
}

function checkPermission (permissions) {
  if (!Array.isArray(permissions)) {
    permissions = [permissions]
  }
  return (to, from, next) => {
    store.getters.currentUserHasAnyPermission(permissions) ? next() : next({ path: '/forbidden' })
  }
}

const AuthLayout = () => import('../layouts/auth-layout.vue')
const MainLayout = () => import('../layouts/main-layout.vue')
const MobilityLayout = () => import('../layouts/mobility-layout.vue')

const routes = [
  // Legacy routing
  {
    path: '/disputes/:id',
    meta: { documentType: 'disputes' },
    beforeEnter: handleAccessFromToken
  },
  {
    path: '/promises/:id',
    meta: { documentType: 'promises' },
    beforeEnter: handleAccessFromToken
  },
  {
    path: '/invoices/:id',
    meta: { documentType: 'invoices' },
    beforeEnter: handleAccessFromToken
  },
  {
    path: '/collaborationTasks/:id',
    meta: { documentType: 'collaboration-tasks' },
    beforeEnter: handleAccessFromToken
  },
  // Auth routes
  {
    path: '/login',
    component: AuthLayout,
    children: [{ path: '', component: () => import('../pages/login.vue'), name: 'login' }]
  },
  {
    path: '/changePassword',
    component: AuthLayout,
    children: [{ path: ':hash', component: () => import('../pages/change-password.vue') }]
  },
  {
    path: '/switch-v5-v6',
    component: AuthLayout,
    children: [{ path: '', component: () => import('../pages/switch-v5-v6.vue'), name: 'switch-v5-v6' }]
  },
  {
    path: '/license',
    component: AuthLayout,
    children: [{ path: '', component: () => import('../pages/license.vue'), name: 'license' }]
  },
  {
    path: '/cgu',
    component: AuthLayout,
    children: [{ path: '', component: () => import('../pages/cgu.vue') }]
  },
  // Mobility
  {
    path: '/mobility',
    component: MobilityLayout,
    children: [
      // Be aware that meta.level is used for sliding transtions direction
      { path: '', component: () => import('../pages/mobility/dashboard.vue'), name: 'mobility-home', meta: { level: 1 } },
      { path: 'account/:id', component: () => import('../pages/mobility/account.vue'), name: 'mobility-account', meta: { level: 2 } },
      { path: 'login', component: () => import('../pages/mobility/login.vue'), name: 'mobility-login', meta: { level: 0 } },
      { path: 'profile', component: () => import('../pages/mobility/profile.vue'), name: 'mobility-profile', meta: { level: 3 } },
      { path: 'cgu', component: () => import('../pages/mobility/cgu.vue'), name: 'mobility-cgu', meta: { level: 4 } },
      { path: 'theme', component: () => import('../pages/mobility/ui-settings.vue'), name: 'mobility-theme', meta: { level: 4 } }
    ]
  },
  {
    path: '/externalLoginUserConnected',
    component: AuthLayout,
    children: [{ path: '', component: () => import('../pages/external-login-user-connected.vue'), name: 'external-login-user-connected' }]
  },
  {
    path: '/externalLoginUserNotMap',
    component: AuthLayout,
    children: [{ path: '', component: () => import('../pages/external-login-user-not-map.vue'), name: 'external-login-user-not-map' }]
  },
  {
    path: '/externalLoginProviderSelection',
    component: AuthLayout,
    children: [
      { path: '', component: () => import('../pages/external-login-provider-selection'), name: 'sso-intern' },
      { path: 'extern', component: () => import('../pages/external-login-provider-selection'), name: 'sso-extern' }
    ]
  },
  // yellow box
  {
    path: '/account-by-number',
    component: AuthLayout,
    children: [{ path: '', component: () => import('../pages/account/account-by-number.vue'), name: 'account-by-number' }]
  },
  // Main routes
  {
    path: '/',
    component: MainLayout,
    children: [
      { path: '', component: () => import('../pages/dashboards/dashboard'), name: 'Dashboard' },
      { path: 'account-contacts', component: () => import('../pages/account-contacts/account-contact.vue'), name: 'new-account-contact' },
      { path: 'account-contacts/:id', component: () => import('../pages/account-contacts/account-contact.vue'), name: 'account-contact' },
      { path: 'account-contacts/new-from-account/:accountId', component: () => import('../pages/account-contacts/account-contact.vue'), name: 'new-contact-from-account', props: route => ({ fromAccountId: route.params.accountId }) },
      { path: 'accounts', component: () => import('../pages/documents-dashboards/accounts/accounts-summary.vue') },
      { path: 'accounts/:id', component: () => import('../pages/account/account.vue'), name: 'account' },
      { path: 'adminDashboard', component: () => import('../pages/dashboards/dashboard-admin') },
      { path: 'administration', component: () => import('../pages/settings/administration.vue'), beforeEnter: checkPermission('RunAdminTasks') },
      { path: 'agentDashboard', component: () => import('../pages/dashboards/agent/dashboard-agent') },
      { path: 'agentSupervisorDashboard', component: () => import('../pages/dashboards/dashboard-agent-supervisor') },
      { path: 'aging-scenarios/:id?', component: () => import('../pages/aging-scenarios/aging-scenario.vue') },
      { path: 'appSettings', component: () => import('../pages/settings/ui-settings.vue') },
      { path: 'business-divisions/:id?', component: () => import('../pages/business-divisions/business-division.vue'), name: 'business-division' },
      { path: 'columns', component: () => import('../pages/cameleon/columns/column.vue'), props: route => ({ targetDocTy: route.query.targetDocTy }) },
      { path: 'columns/:id', component: () => import('../pages/cameleon/columns/column.vue') },
      { path: 'components', component: () => import('../pages/samples/components.vue') },
      { path: 'dunning-runs/:id', component: () => import('../pages/escalation-protocols/dunning-run.vue') },
      { path: 'engine/bulk-action/:id', component: () => import('../pages/engines/bulk-action.vue') },
      { path: 'engine/collaboration-task/:id', component: () => import('../pages/engines/collaboration-task.vue') },
      { path: 'engine/groupama/:id', component: () => import('../pages/engines/groupama.vue') },
      { path: 'engine/credit-monitoring/:id', component: () => import('../pages/engines/credit-monitoring.vue') },
      { path: 'engine/dispute/:id', component: () => import('../pages/engines/dispute.vue') },
      { path: 'engine/external-risk/:id', component: () => import('../pages/engines/external-risk.vue') },
      { path: 'engine/import/:id', component: () => import('../pages/engines/import.vue') },
      { path: 'engine/incoming-message/:id', component: () => import('../pages/engines/incoming-message.vue') },
      { path: 'engine/promise/:id', component: () => import('../pages/engines/promise.vue') },
      { path: 'engine/purge/:id', component: () => import('../pages/engines/purge.vue') },
      { path: 'engine/risk-category/:id', component: () => import('../pages/engines/risk-category.vue') },
      { path: 'engine/work-item/:id', component: () => import('../pages/engines/work-item.vue') },
      { path: 'escalation-protocols/:id?', component: () => import('../pages/escalation-protocols/escalation-protocol.vue') },
      { path: 'events', component: () => import('../pages/allocation/events.vue') },
      { path: 'forbidden', component: () => import('../pages/forbidden.vue') },
      { path: 'import/:id', component: () => import('../pages/import/import-recap.vue') },
      { path: 'label-groups/:id?', component: () => import('../pages/labels/label-group.vue') },
      { path: 'labels/:id?', component: () => import('../pages/labels/label.vue') },
      { path: 'logfile-v5', component: () => import('../pages/logfile-v5.vue'), beforeEnter: checkPermission(['RunAdminTasks', 'Treatments']) },
      { path: 'messages-unassigned/:id', component: () => import('../pages/messages/message-unassigned.vue') },
      { path: 'new-import/:id?', component: () => import('../pages/import/new-import.vue') },
      { path: 'processes/:id?', component: () => import('../pages/processes/process.vue') },
      { path: 'processing', component: () => import('../pages/settings/processing.vue'), beforeEnter: checkPermission('Treatments') },
      { path: 'providers/:id?', component: () => import('../pages/providers/provider.vue') },
      { path: 'reports-v5', component: () => import('../pages/reports.vue'), beforeEnter: checkPermission('Reports') },
      { path: 'runs/progress/:id?', component: () => import('../pages/runs/progress.vue') },
      { path: 'search', component: () => import('../pages/search/search.vue'), name: 'search' },
      { path: 'search-v5/:id', component: () => import('../pages/search/search-v5.vue') },
      { path: 'settings', component: () => import('../pages/settings/index.vue') },
      { path: 'topics/:id?', component: () => import('../pages/topics/topic.vue') },
      { path: 'users', component: () => import('../pages/users/user.vue'), name: 'new-user' },
      { path: 'users/:id', component: () => import('../pages/users/user.vue'), name: 'user' },
      { path: 'work-queues/:id?', component: () => import('../pages/work-queues/work-queue.vue') }
    ]
  },
  // 404
  {
    path: '*',
    component: MainLayout,
    children: [{ path: '', component: () => import('../pages/not-found'), name: 'not-found' }]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const currentUser = store.getters.currentUser
  await licenseController.checkLicense()
  await authenticationController.initialFetch()

  if (
    to.path === '/login' ||
    to.path === '/mobility/login' ||
    to.path === '/externalLoginUserConnected' ||
    to.path === '/externalLoginUserNotMap' ||
    to.path.includes('/externalLoginProviderSelection') ||
    to.path === '/switch-v5-v6' ||
    to.path === '/cgu' ||
    to.path === '/mobility/cgu' ||
    to.path === '/forgot-password' ||
    to.path.includes('/changePassword') ||
    (to.path === '/license' && typeof licenseController.valid !== 'undefined')
  ) {
    return next()
  }

  if (typeof licenseController.valid === 'undefined') {
    if (authenticationController.needExternalSelection()) {
      return next({ path: '/externalLoginProviderSelection' + (authenticationController.isOutsider() ? '/extern' : '') })
    }

    authenticationController.redirectExternalLoginIfPossible()
    return next({ path: '/login' })
  }

  if (!currentUser.id && to.path.includes('mobility')) {
    return next({ name: 'mobility-login' })
  }

  if (!currentUser.id) {
    const redirection = (to.fullPath.length > 1 && to.name !== 'license') ? { redirect: to.fullPath } : undefined

    if (authenticationController.needExternalSelection()) {
      return next({ path: '/externalLoginProviderSelection' + (authenticationController.isOutsider() ? '/extern' : ''), query: redirection })
    }

    authenticationController.redirectExternalLoginIfPossible(null, redirection?.redirect)
    return next({ path: '/login', query: redirection })
  }

  if (!licenseController.valid) {
    return next({ path: '/license' })
  }

  if (!currentUser.isCguAccepted && to.path.includes('mobility')) {
    return next({ name: 'mobility-cgu' })
  }

  if (!currentUser.isCguAccepted) {
    return next({ path: '/cgu' })
  }

  next()
})

export default router
