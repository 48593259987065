import { navigatorCulture, setCurrentCulture } from '../../plugins/i18n'

const currentUser = (function loadCurrentUser () {
  let user
  try {
    user = JSON.parse(sessionStorage.getItem('currentUser'))
  } catch { }

  return Object.assign({
    id: null,
    culture: navigatorCulture(),
    currency: 'EUR',
    exportSettings: null,
    systemCurrency: 'EUR',
    permissions: [],
    groupCode: null,
    fullname: null,
    email: null,
    group: null,
    isCguAccepted: null,
    mainPresetId: null,
    divisions: []
  }, user)
})()

setCurrentCulture(currentUser.culture)

export default {
  state: () => ({
    currentUser
  }),
  getters: {
    currentUser: state => state.currentUser,

    currentUserHasPermission: state => permissionName => state.currentUser.isMasterAdmin ||
      (state.currentUser.permissions && (state.currentUser.permissions.includes(permissionName))),

    currentUserHasAnyPermission: state => permissionNames => state.currentUser.isMasterAdmin ||
      (state.currentUser.permissions && (permissionNames.some(p => state.currentUser.permissions.includes(p)))),

    currentUserHasEveryPermissions: state => permissionNames => state.currentUser.isMasterAdmin ||
      (state.currentUser.permissions && (permissionNames.every(p => state.currentUser.permissions.includes(p)))),

    isAdmin: state => () => state.currentUser.groupCode === 'admins',

    isMasterAdmin: state => () => state.currentUser.isMasterAdmin,

    isBusinessDivisionSupervisor: state => () => state.currentUser.isBusinessDivisionSupervisor
  },
  mutations: {
    setCurrentUser (state, _ = {}) {
      state.currentUser = {
        culture: _.culture,
        currency: _.currency,
        divisions: _.divisions,
        email: _.email,
        exportSettings: _.exportSettings,
        fullname: _.fullname,
        group: _.group,
        groupCode: _.groupCode,
        hasNewReleaseNotes: _.hasNewReleaseNotes,
        id: _.id,
        isBusinessDivisionSupervisor: _.isBusinessDivisionSupervisor,
        isCguAccepted: _.isCguAccepted,
        isMasterAdmin: _.isMasterAdmin,
        mainPresetId: _.mainPresetId,
        permissions: _.permissions,
        settings: _.settings,
        systemCurrency: _.systemCurrency
      }
    }
  },
  actions: {
    async getCurrentUserSettings ({ commit, state, dispatch }) {
      const [
        userRequestV6,
        userCurrencyRequest,
        userExportRequest,
        globalRequest,
        releaseInfo
      ] = await Promise.all([
        this._vm.$http().get('/core/v6/users/authenticating-user'),
        this._vm.$http().get('/core/settings/user/currency'),
        this._vm.$http().get('/core/settings/user/export'),
        this._vm.$http().get('/core/v6/settings/global'),
        this._vm.$http().get('/core/v6/users/release-info')
      ])
      const user = userRequestV6.data

      user.currency = userCurrencyRequest.data.currency
      user.exportSettings = userExportRequest.data
      user.systemCurrency = globalRequest.data.applicationSettings.system_currency
      user.settings = {
        clickToDialProtocol: globalRequest.data.clickToDialSettings.protocol,
        dunningManualValidationMode: globalRequest.data.escalationSettings.manualValidationMode,
        emailsSystem: globalRequest.data.emailsSystem,
        riskCategoryType: globalRequest.data.riskCategorySettings.type
      }

      user.hasNewReleaseNotes = releaseInfo.data.hasNewReleaseNotes

      const divisionRequest = user.isBusinessDivisionSupervisor ? await this._vm.$http().post('/core/dashboard/division-manager', {}) : {}
      user.divisions = divisionRequest?.data?.divisions ?? []

      dispatch('setCurrentUser', Object.assign({}, state.currentUser, user))
    },
    async setCurrentUser ({ state, dispatch, commit }, value) {
      const changedProperties = Object.keys(value)
      const currentUser = Object.assign({}, state.currentUser, value)
      commit('setCurrentUser', currentUser)
      dispatch('storeInBrowser')

      let reload = false

      if (!changedProperties.includes('id')) {
        if (changedProperties.includes('currency')) {
          await this._vm.$http().post('core/settings/user/currency', { currency: value.currency })
          reload = true
        }

        if (changedProperties.includes('exportSettings')) {
          await this._vm.$http().post('core/settings/user/export', { dateFormat: value.exportSettings.dateFormat, fieldDelimiter: value.exportSettings.fieldDelimiter, decimalDelimiter: value.exportSettings.decimalDelimiter, fileName: value.exportSettings.fileName })
        }

        if (changedProperties.includes('culture')) {
          await this._vm.$http().put(`/core/v6/users/${currentUser.id}/settings`, { culture: value.culture })
          reload = true
        }

        if (changedProperties.includes('mainPresetId')) {
          await this._vm.$http().put(`/core/v6/users/${currentUser.id}/settings`, { mainPresetId: value.mainPresetId })
        }

        if (changedProperties.includes('isCguAccepted')) {
          await this._vm.$http().put(`/core/v6/users/${currentUser.id}/settings`, { isCguAccepted: value.isCguAccepted })
        }

        if (changedProperties.includes('isNewReleaseNotesRead')) {
          await this._vm.$http().put(`/core/v6/users/${currentUser.id}/settings`, { isNewReleaseNotesRead: true })
        }
      }

      if (reload) {
        location.reload()
      } else {
        if (changedProperties.includes('culture')) {
          setCurrentCulture(state.currentUser?.culture)
        }
      }
    },
    clearCurrentUser ({ commit, dispatch }) {
      sessionStorage.removeItem('currentUser')
      commit('setCurrentUser')
    },
    storeInBrowser ({ state }) {
      window.sessionStorage.currentUser = JSON.stringify(state.currentUser)
    }
  }
}
